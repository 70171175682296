
import { Vue, Component, Watch } from 'vue-property-decorator'
import { DoctorInfo } from '@/components'
import { ClinicAPI } from '@/api'
import globalSetting from '@/utils/globalSetting'

@Component({
  components: {
    DoctorInfo
  }
})
export default class DoctorList extends Vue {
  public $refs!: any
  private clinicAddress = null
  protected apiAction = ClinicAPI.getClinicDoctorList
  private tabTitle = true
  private hospitalId = 0
  private activeTab = 0
  private spinShow = false
  private tabs = [
    {
      index: 0,
      apiParams: {
        condition: '',
        tag: '全部'
      },
      list: []
    },
    {
      index: 1,
      apiParams: {
        condition: '',
        tag: '青少年'
      },
      list: []
    },
    {
      index: 2,
      apiParams: {
        condition: '',
        tag: '成年'
      },
      list: []
    }
  ]
  private clickTab: string[] = ['', '', '']
  protected async created () {
    const config = await globalSetting.getSetting()
  }
  private tabsClick (index: number, type?: string) {
    this.activeTab = index
    if (!type) {
      if (this.clickTab.indexOf(this.tabs[index].apiParams.tag) > -1) return
    }
    this.spinShow = true
    this.apiAction({ ...this.tabs[index].apiParams, hospitalId: this.hospitalId, ps: 100, pn: 1 }).then((res) => {
      setTimeout(() => {
        this.spinShow = false
      }, 500)
      this.clickTab[index] = this.tabs[index].apiParams.tag
      this.tabs[index].list = res.result.list
    })
  }
  private search (name: string) {
    if (!name) {
      return this.$Message.error('请输入疾病名称或医生姓名进行搜索')
    }
    this.tabs[0].apiParams = {
      condition: name,
      tag: '全部'
    }
    this.tabTitle = false
    this.tabsClick(0, 'search')
  }
  private format () {
    this.tabs[0].apiParams = {
      condition: '',
      tag: '全部'
    }
    this.tabTitle = true
    this.tabsClick(0, 'search')
  }
  private mounted () {
    this.getHospitalClinic()
  }
  private getHospitalClinic () {
    ClinicAPI.getHospitalClinic().then((res) => {
      this.clinicAddress = res.result
      this.hospitalId = res.result.id
      this.tabsClick(0)
      // this.getClinicDoctorList()
    })
  }
  private beforeDestory () {
    this.clickTab = ['全部', '', '']
  }
}
